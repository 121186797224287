export default {

    computed: {

        b2bLocales() {
            return [
                { text: "English", value: "en-GB" },
                { text: "German", value: "de-CH" },
                { text: "French", value: "fr-FR" },
                { text: "Italian", value: "it-IT" },
            ];
        },

        roles() {
            return [
                { text: "SALES", value: "Sales" },
                { text: "SALESADMIN", value: "SalesAdmin" },
                { text: "SALESCURRENCYEDIT", value: "SalesCurrencyEdit" },
                { text: "VENDINGMACHINE", value: "VendingMachine" },
                { text: "PICKUPMACHINE", value: "PickupMachine" },
            ];
        },
        printerTypes() {
            return [
                { text: "AXESS_ACPX", value: "AXESS_ACPX" },
            ];
        },
        resellerOperation() {
            return [
                { text: "Cashdesk Sale", value: "cashdesksale" },
            ];
        },
        resellerPaymentType() {
            return [
                { text: "Invoice", value: "invoice" },
                { text: "ccAlias", value: "ccAlias" },
            ];
        },

        trayConfigurations() {
            return [
                {
                    text: "Skipass within tray / Mountain railway via hand feed",
                    value: "skipass_bergbahn"
                },
                {
                    text: "Mountain railway within tray / Skipass via hand feed",
                    value: "bergbahn_skipass"
                },
                {
                    text: "Skipass within tray / Mountain railway as PDF",
                    value: "skipass_voucher"
                },
                {
                    text: "Mountain railway via hand feed / Skipass as Pickup",
                    value: "bergbahnhand_skipasspickup"
                },
                {
                    text: "Mountain railway within tray / Skipass as Pickup",
                    value: "bergbahntray_skipasspickup"
                }
            ]
        }

    },


}