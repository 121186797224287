var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"reseller-contract-items-container",attrs:{"fluid":"","fill-height":"","data-test-id":"ContractItemsList"}},[_c('v-card',{staticClass:"reseller-contract-items",attrs:{"flat":"","id":"contractItems"}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-sitemap-outline")]),_vm._v(" Contract Items "),_c('v-spacer'),(_vm.isEditActive)?_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"green","data-test-id":"saveAllContractItemBtn","disabled":_vm.disabled || _vm.runningAction},on:{"click":_vm.upsertAllContractItems}},[_vm._v(" Save all ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disabled || _vm.runningAction,"data-test-id":"addContractItemBtn"},on:{"click":_vm.addContractItem}},[_vm._v(" New contract item ")])],1),_c('v-form',{ref:"contractItemForm"},[_c('v-data-table',{staticClass:"reseller-contract-items-table",attrs:{"dense":"","sort-by":"preferred","sort-desc":"","hide-default-footer":"","loading":_vm.runningAction,"items":_vm.displayedItems,"headers":_vm.contractItemHeaders,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.skuPattern",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('div',{staticClass:"contract-item-field"},[_c('PatternHelperInput',{ref:'skuPatternInput_' + item.id,staticClass:"my-1",attrs:{"outlined":"","dense":"","hide-details":"auto","placeholder":"*.SKIPASS.*","background-color":"white","disabled":_vm.disabled || _vm.runningAction,"rules":[_vm.ruleSet.required],"is-service-provider":_vm.isServiceProvider,"parent-id":item.id},model:{value:(item.skuPattern),callback:function ($$v) {_vm.$set(item, "skuPattern", $$v)},expression:"item.skuPattern"}})],1):_c('div',[_vm._v(_vm._s(item.skuPattern))])]}},{key:"item.commissionPercentage",fn:function(ref){
var item = ref.item;
return [(item.edit)?_c('div',{staticClass:"contract-item-field"},[_c('v-text-field',{staticClass:"my-1",attrs:{"outlined":"","dense":"","hide-details":"auto","placeholder":"5","type":"number","background-color":"white","disabled":_vm.disabled || _vm.runningAction,"rules":[_vm.ruleSet.required],"data-test-id":'commissionPercentageInput_' + item.id},model:{value:(item.commissionPercentage),callback:function ($$v) {_vm.$set(item, "commissionPercentage", $$v)},expression:"item.commissionPercentage"}})],1):_c('div',[_vm._v(_vm._s(item.commissionPercentage))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
              'action-btn-container': true,
              edit: item.edit,
            }},[(item.edit || item.isNew)?_c('v-btn',{attrs:{"small":"","icon":"","color":"green","disabled":_vm.disabled ||
                _vm.runningAction ||
                !item.skuPattern ||
                !item.commissionPercentage,"data-test-id":'contractItemSaveBtn_' + item.id},on:{"click":function($event){return _vm.upsertContractItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")])],1):_c('v-btn',{attrs:{"small":"","icon":"","disabled":_vm.disabled || _vm.runningAction,"data-test-id":'contractItemEditBtn_' + item.id},on:{"click":function($event){return _vm.toggleEditMode(item, true)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),(item.edit && !item.isNew)?_c('v-btn',{attrs:{"small":"","icon":"","disabled":_vm.disabled || _vm.runningAction,"data-test-id":'contractItemCancelBtn_' + item.id},on:{"click":function($event){return _vm.toggleEditMode(item, false)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1):_c('v-btn',{attrs:{"small":"","icon":"","disabled":_vm.disabled || _vm.runningAction,"data-test-id":'contractItemDeleteBtn_' + item.id},on:{"click":function($event){return _vm.deleteContractItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }