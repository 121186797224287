<template>
  <MasterDetail
    class="service-provider-overview"
    data-test-id="serviceProviderOverview"
    :detail-open="!!selectedProvider"
  >
    <template #toolbar>
      <Toolbar
        v-model="filterQuery"
        class="service-provider-toolbar"
        :search-props="{
          disableFullTextSearch: true,
          possibleFilters,
        }"
        @update-filters="(updated) => (filters = updated)"
      />
    </template>

    <template #table>
      <v-data-table
        class="cart-rule-table"
        dense
        hide-default-footer
        fixed-header
        multi-sort
        single-select
        item-key="id"
        :item-class="getItemClass"
        :height="tableHeight"
        :items-per-page="-1"
        :items="displayedItems"
        :headers="headers"
        :options.sync="options"
        :loading="runningAction"
        @click:row="openServiceProviderDetail"
      />
    </template>

    <template #detail>
      <ServiceProviderDetail
        v-if="selectedProvider"
        :provider="selectedProvider"
        :key="selectedProvider.id"
        :style="{
          height: detailHeight + 'px',
        }"
        @close="closeServiceProviderDetail"
      />
    </template>
  </MasterDetail>
</template>

<script>
import MasterDetail from "../common/templates/MasterDetail";
import ServiceProviderDetail from "./ServiceProviderDetail";
import mainOverviewMixin from "../../mixins/main-overview-mixin";
import Toolbar from "../common/templates/Toolbar.vue";

export default {
  mixins: [mainOverviewMixin],

  components: {
    MasterDetail,
    ServiceProviderDetail,
    Toolbar,
  },

  data() {
    return {
      selectedProvider: null,
      runningAction: false,
      serviceProviders: [],
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
      filterQuery: null,
      filters: {},
      recalculateItemsKey: false,
    };
  },

  created() {
    this.init();
  },

  watch: {
    async options(options) {
      let query = Object.assign({}, this.$route.query);
      const sortBy = options.sortBy;
      const sortDesc = options.sortDesc;
      let sort = "";

      //build sort url parameter value
      if (Array.isArray(sortBy)) {
        for (let i = 0; i < sortBy.length; i++) {
          const sortQuery = (sortDesc[i] ? "-" : "") + sortBy[i];
          if (!sort) sort = sortQuery;
          else sort += "," + sortQuery;
        }
      }

      if (!sort) this.$delete(query, "sort");
      else this.$set(query, "sort", sort);

      await this.$router.push({
        ...this.$route,
        query,
      });
    },

    filterQuery: {
      handler: async function (filterQuery) {
        let query = Object.assign({}, this.$route.query);
        //remove filter query if the query is faulty
        if (Object.keys(filterQuery).length > 0) {
          let copy = this.$cloneObject(filterQuery);
          if (this.$isPlainObject(copy)) {
            copy = JSON.stringify(copy);
          }
          query.filter = this.$urlEncode(copy);
        } else {
          delete query.filter;
        }
        await this.$router.push({ ...this.$route, query });
        await this.$nextTick();
        this.recalculateItemsKey = !this.recalculateItemsKey;
      },
      deep: true,
    },
  },

  methods: {
    async init() {
      const routeQuery = this.$route.query;
      let sort = routeQuery.sort;
      let filter = routeQuery.filter;

      if (sort) {
        const sortBy = [];
        const sortDesc = [];
        const sortQuery = sort.split(",");

        const addToSorted = (sortString) => {
          //this function parses the given string and adds the
          //sorting information to the respective arrays
          const isDesc = sortString.startsWith("-");
          const property = isDesc ? sortString.substring(1) : sortString;
          const isSortableField = this.headers.some(
            (header) => header.value === property && header.sortable !== false
          );
          if (isSortableField) {
            sortBy.push(property);
            sortDesc.push(isDesc);
          }
        };

        if (Array.isArray(sortQuery))
          sortQuery.forEach((part) => addToSorted(part));
        else addToSorted(sortQuery);
        this.options = Object.assign(this.options, { sortBy, sortDesc });
      }

      if (filter) {
        try {
          filter = this.$urlDecode(filter);
          this.filterQuery = JSON.parse(filter);
        } catch (e) {
          this.filterQuery = null;
          console.warn(e);
        }
      }

      let query = {};
      if (this.filterQuery)
        query.filter = this.$urlEncode(JSON.stringify(this.filterQuery));
      if (this.options.sortBy?.length > 0 && this.options.sortDesc?.length > 0)
        query.sort = sort;

      //update the URL query
      await this.loadServiceProviders();
      if (
        this.$route.matched.some(({ name }) => name === "serviceProviderDetail")
      ) {
        const providerId = this.$route?.params?.provider;
        const provider = this.serviceProviders.find(
          ({ id }) => providerId === id
        );
        if (!provider) {
          this.$store.dispatch(
            "setError",
            "Cannot find service privider with id " + providerId
          );
          this.closeServiceProviderDetail();
          return;
        }
        if (this.selectedProvider?.id === providerId) {
          this.selectedProvider = provider;
        } else {
          this.openServiceProviderDetail(provider);
        }
      } else {
        this.selectedProvider = null;
      }
    },

    async loadServiceProviders() {
      try {
        this.runningAction = true;
        this.serviceProviders =
          await this.$store.$coreApi.coreServiceProviderApi.getServiceProviders(
            this.selectedDomain
          );
      } finally {
        if (!this.serviceProviders) this.serviceProviders = [];
        this.runningAction = false;
      }
    },

    async openServiceProviderDetail(provider) {
      await this.$router.push({
        name: "serviceProviderDetail",
        params: {
          provider: provider.id,
        },
        query: this.$route.query,
      });

      this.selectedProvider = provider;
    },

    async closeServiceProviderDetail(reload) {
      await this.$router.push({
        name: "serviceProviders",
        query: this.$route.query,
      });

      this.selectedProvider = null;
      if (reload) this.init(true);
    },

    getItemClass(item) {
      let itemClass = ["service-provider-item", item.id];
      if (item.id === this.selectedProvider?.id) itemClass.push("selected");
      return itemClass.join(" ");
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    headers() {
      return [
        { text: "Name", value: "name", cellClass: "service-provider-name" },
        { text: "Id", value: "id", cellClass: "service-provider-id" },
      ];
    },

    possibleFilters() {
      return [
        { text: "Name", property: "name", type: "text" },
        { text: "ID", property: "id", type: "text" },
      ];
    },

    displayedItems() {
      this.recalculateItemsKey;
      const serviceProviders = this.serviceProviders ?? [];
      if (Object.keys(this.filters).length === 0) return serviceProviders;
      return serviceProviders.filter((provider) => {
        return Object.values(this.filters).every((filter) => {
          const value = filter.value;
          const property = filter.property;
          if (!value) return true;
          return provider[property].includes(value);
        });
      });
    },
  },
};
</script>

<style scoped>
</style>