import { render, staticRenderFns } from "./PatternHelperDialog.vue?vue&type=template&id=5141f533&scoped=true&"
import script from "./PatternHelperDialog.vue?vue&type=script&lang=js&"
export * from "./PatternHelperDialog.vue?vue&type=script&lang=js&"
import style0 from "./PatternHelperDialog.vue?vue&type=style&index=0&id=5141f533&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5141f533",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VToolbarTitle,VTooltip})
