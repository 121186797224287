<template>
  <DialogForm
    class="new-contract-form"
    data-test-id="contract"
    :title="title"
    :flat="!modeCreate"
    :loading="runningAction"
  >
    <template #actions="{ loading }">
      <v-btn
        v-if="modeCreate"
        @click="upsertContract"
        :disabled="loading"
        color="green"
        data-test-id="createContractBtn"
      >
        Save
      </v-btn>
      <v-btn
        v-if="modeCreate"
        @click="closeDialog()"
        text
        class="close-dialog-btn"
        data-test-id="closeContractDialogBtn"
        :disabled="loading"
      >
        Cancel
      </v-btn>

      <v-btn
        icon
        v-if="!modeCreate && !edit"
        @click="editContract"
        :disabled="disabled || loading"
        data-test-id="contract_editBtn"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="!modeCreate && edit"
        @click="upsertContract"
        :disabled="loading"
        data-test-id="contract_updateBtn"
      >
        <v-icon small>mdi-content-save-outline</v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="!modeCreate && edit"
        @click="stopEdit"
        :disabled="loading"
        data-test-id="contract_closeBtn"
      >
        <v-icon small>mdi-pencil-off</v-icon>
      </v-btn>
    </template>

    <template #form="{ loading }">
      <v-form @submit.prevent ref="contractForm">
        <v-container fluid class="pa-0">
          <v-row>
            <v-col>
              <DomainSelector
                v-if="modeCreate && !isServiceProvider"
                v-model="srcDomain"
                label="Source domain... *"
                outlined
                data-test-id="contractDomainSelector"
                hide-details="auto"
                disable-domain-creation
                set-local
                :rules="domainInputRules"
                :domain-range="domainRange"
                :disabled="loading || isDisabled"
              />
            </v-col>
          </v-row>
          <v-row
            :class="{
              'data-input-container': true,
              create: modeCreate,
            }"
          >
            <v-col>
              <v-text-field
                v-model="contract.validFrom"
                ref="input"
                type="date"
                outlined
                dense
                class="date-picker"
                label="Valid from *"
                data-test-id="contractValidFrom"
                hide-details="auto"
                :max="maxDate"
                :disabled="isDisabled || loading"
                :rules="[
                  ruleSet.required,
                  ruleSet.beforeUntilDate(contract.validUntil),
                ]"
                @blur="$emit('input', contract.validFrom || '')"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="contract.validUntil"
                ref="input"
                type="date"
                outlined
                dense
                class="date-picker create-contact-valid-to"
                label="Valid to *"
                data-test-id="contractValidTo"
                hide-details="auto"
                :min="minDate"
                :rules="[
                  ruleSet.required,
                  ruleSet.afterFromDate(contract.validFrom),
                ]"
                :disabled="loading || isDisabled"
                @blur="$emit('input', contract.validUntil || '')"
              />
            </v-col>
          </v-row>
          <v-row v-if="!modeCreate && !isServiceProvider">
            <v-col>
              <v-select
                v-model="contract.paymentTypes"
                dense
                outlined
                label="Payment types"
                class="contract-payment-type"
                multiple
                data-test-id="contractPaymentType"
                hide-details="auto"
                :items="resellerPaymentType"
                :disabled="loading || isDisabled"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="contract.operations"
                dense
                outlined
                label="Operations"
                multiple
                class="contract-operatrions"
                data-test-id="contractOperatrions"
                hide-details="auto"
                :disabled="loading || isDisabled"
                :items="resellerOperation"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-if="!modeCreate"
                v-model="contract.contactPerson"
                dense
                outlined
                label="Contact person"
                class="create-contact-person"
                data-test-id="contactPerson"
                hide-details="auto"
                :disabled="loading || isDisabled"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </DialogForm>
</template>


<script>
import DialogForm from "components/common/templates/DialogForm";
import ResellerEnum from "mixins/enum/ResellerEnum";
import DomainSelector from "components/domain/DomainSelector";
import validationMixin from "mixins/field-rule-validation";

export default {
  name: "Contract",
  mixins: [ResellerEnum, validationMixin],
  props: {
    inputContract: {
      required: false,
      default: null,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    entity: {
      required: true,
      type: Object,
    },

    domain: {
      type: String,
      required: true,
    },

    isServiceProvider: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DomainSelector,
    DialogForm,
  },

  data() {
    return {
      contract: {},
      srcDomain: {
        id: !this.modeCreate
          ? this.inputContract.srcDomain
          : this.selectedDomain,
      },
      runningAction: false,
      domainInputRules: [(v) => (!!v && !!v.id) || "Value is required"],
      edit: false,
    };
  },

  mounted() {
    this.initContract();
  },

  watch: {
    "inputContract.id": function () {
      //another contract has been selected
      this.contract = this.inputContract;
    },
  },

  methods: {
    async upsertContract() {
      const form = this.$refs.contractForm;
      if (this.$validateVForm(form)) {
        this.runningAction = true;
        this.$set(this.contract, "srcDomain", this.srcDomain.id);
        try {
          if (this.isServiceProvider) {
            if (this.modeCreate) {
              this.$set(this.contract, "id", this.$uuid.v4());
              this.$set(this.contract, "contractorId", this.entity.id);
              this.$set(this.contract, "type", "SERVICEPROVIDER");
            }
            const res =
              await this.$store.$coreApi.coreCommissionApi.upsertContract(
                this.domain,
                this.contract,
                {
                  successMsg:
                    "Contract " +
                    this.contract.id +
                    (this.modeCreate ? " created" : " updated"),
                }
              );
            if (!res?.ok) return;

            if (this.modeCreate) {
              this.closeDialog(this.contract.id);
            } else {
              this.$emit("refresh");
              this.edit = false;
            }
          } else {
            if (!this.modeCreate) {
              const res =
                await this.$store.$b2bApi.b2bResellerApi.updateContract(
                  this.domain,
                  this.entity.id,
                  this.contract
                );
              if (!res?.ok) return;
              this.$emit("refresh");
              this.edit = false;
            } else {
              const res =
                await this.$store.$b2bApi.b2bResellerApi.createContact(
                  this.domain,
                  this.entity.id,
                  this.contract
                );
              if (!res?.ok) return;
              const createdContract = await res.json();
              this.closeDialog(createdContract?.id);
            }
          }
        } finally {
          this.runningAction = false;
        }
      }
    },

    closeDialog(newContractId) {
      this.createContract = false;
      this.contract = {};
      this.$emit("close", newContractId);
    },

    editContract() {
      this.edit = true;
    },

    stopEdit() {
      this.edit = false;
      this.contract = this.$cloneObject(this.inputContract);
    },

    initContract() {
      let contract = !this.modeCreate
        ? this.$cloneObject(this.inputContract)
        : {};
      if (this.modeCreate) {
        const today = new Date().toISOString().split("T")[0];
        this.$set(contract, "validFrom", today);
        this.srcDomain = { id: this.selectedDomain };
      }
      this.contract = contract;
    },
  },

  computed: {
    title() {
      return this.modeCreate ? "Create Contract" : "Contract";
    },

    isDisabled() {
      return (!this.edit || this.runningAction) && !this.modeCreate;
    },
    modeCreate() {
      return !this.inputContract || !this.inputContract.id;
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    minDate() {
      const validFrom = this.contract.validFrom;
      if (validFrom) {
        const minDate = new Date(validFrom).toISOString().split("T")[0];
        return minDate;
      }
      return undefined;
    },

    maxDate() {
      const validTo = this.contract.validTo;
      if (validTo) {
        const maxDate = new Date(validTo).toISOString().split("T")[0];
        return maxDate;
      }
      return undefined;
    },

    domainRange() {
      return {
        from: {
          id: this.selectedDomain,
          exclusive: false,
        },
        until: {
          id: this.domain,
          exclusive: true,
        },
      };
    },
  },
};
</script>

<style scoped>
.new-contract-form .data-input-container {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
}

.new-contract-form .data-input-container.create {
  flex-direction: column;
}
</style>