var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogForm',{staticClass:"new-contract-form",attrs:{"data-test-id":"contract","title":_vm.title,"flat":!_vm.modeCreate,"loading":_vm.runningAction},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var loading = ref.loading;
return [(_vm.modeCreate)?_c('v-btn',{attrs:{"disabled":loading,"color":"green","data-test-id":"createContractBtn"},on:{"click":_vm.upsertContract}},[_vm._v(" Save ")]):_vm._e(),(_vm.modeCreate)?_c('v-btn',{staticClass:"close-dialog-btn",attrs:{"text":"","data-test-id":"closeContractDialogBtn","disabled":loading},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cancel ")]):_vm._e(),(!_vm.modeCreate && !_vm.edit)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.disabled || loading,"data-test-id":"contract_editBtn"},on:{"click":_vm.editContract}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e(),(!_vm.modeCreate && _vm.edit)?_c('v-btn',{attrs:{"icon":"","disabled":loading,"data-test-id":"contract_updateBtn"},on:{"click":_vm.upsertContract}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save-outline")])],1):_vm._e(),(!_vm.modeCreate && _vm.edit)?_c('v-btn',{attrs:{"icon":"","disabled":loading,"data-test-id":"contract_closeBtn"},on:{"click":_vm.stopEdit}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-off")])],1):_vm._e()]}},{key:"form",fn:function(ref){
var loading = ref.loading;
return [_c('v-form',{ref:"contractForm",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[(_vm.modeCreate && !_vm.isServiceProvider)?_c('DomainSelector',{attrs:{"label":"Source domain... *","outlined":"","data-test-id":"contractDomainSelector","hide-details":"auto","disable-domain-creation":"","set-local":"","rules":_vm.domainInputRules,"domain-range":_vm.domainRange,"disabled":loading || _vm.isDisabled},model:{value:(_vm.srcDomain),callback:function ($$v) {_vm.srcDomain=$$v},expression:"srcDomain"}}):_vm._e()],1)],1),_c('v-row',{class:{
            'data-input-container': true,
            create: _vm.modeCreate,
          }},[_c('v-col',[_c('v-text-field',{ref:"input",staticClass:"date-picker",attrs:{"type":"date","outlined":"","dense":"","label":"Valid from *","data-test-id":"contractValidFrom","hide-details":"auto","max":_vm.maxDate,"disabled":_vm.isDisabled || loading,"rules":[
                _vm.ruleSet.required,
                _vm.ruleSet.beforeUntilDate(_vm.contract.validUntil) ]},on:{"blur":function($event){return _vm.$emit('input', _vm.contract.validFrom || '')}},model:{value:(_vm.contract.validFrom),callback:function ($$v) {_vm.$set(_vm.contract, "validFrom", $$v)},expression:"contract.validFrom"}})],1),_c('v-col',[_c('v-text-field',{ref:"input",staticClass:"date-picker create-contact-valid-to",attrs:{"type":"date","outlined":"","dense":"","label":"Valid to *","data-test-id":"contractValidTo","hide-details":"auto","min":_vm.minDate,"rules":[
                _vm.ruleSet.required,
                _vm.ruleSet.afterFromDate(_vm.contract.validFrom) ],"disabled":loading || _vm.isDisabled},on:{"blur":function($event){return _vm.$emit('input', _vm.contract.validUntil || '')}},model:{value:(_vm.contract.validUntil),callback:function ($$v) {_vm.$set(_vm.contract, "validUntil", $$v)},expression:"contract.validUntil"}})],1)],1),(!_vm.modeCreate && !_vm.isServiceProvider)?_c('v-row',[_c('v-col',[_c('v-select',{staticClass:"contract-payment-type",attrs:{"dense":"","outlined":"","label":"Payment types","multiple":"","data-test-id":"contractPaymentType","hide-details":"auto","items":_vm.resellerPaymentType,"disabled":loading || _vm.isDisabled},model:{value:(_vm.contract.paymentTypes),callback:function ($$v) {_vm.$set(_vm.contract, "paymentTypes", $$v)},expression:"contract.paymentTypes"}})],1),_c('v-col',[_c('v-select',{staticClass:"contract-operatrions",attrs:{"dense":"","outlined":"","label":"Operations","multiple":"","data-test-id":"contractOperatrions","hide-details":"auto","disabled":loading || _vm.isDisabled,"items":_vm.resellerOperation},model:{value:(_vm.contract.operations),callback:function ($$v) {_vm.$set(_vm.contract, "operations", $$v)},expression:"contract.operations"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[(!_vm.modeCreate)?_c('v-text-field',{staticClass:"create-contact-person",attrs:{"dense":"","outlined":"","label":"Contact person","data-test-id":"contactPerson","hide-details":"auto","disabled":loading || _vm.isDisabled},model:{value:(_vm.contract.contactPerson),callback:function ($$v) {_vm.$set(_vm.contract, "contactPerson", $$v)},expression:"contract.contactPerson"}}):_vm._e()],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }