var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogForm',{attrs:{"data-test-id":"patternHelperDialog","save-btn-label":"Apply","close-btn-label":"Cancel","save-btn-test-id":"applyPatternBtn","close-btn-test-id":"cancelPatternBtn"},on:{"close":function($event){return _vm.$emit('close', false)},"save":_vm.applyPattern},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-toolbar-title',{staticClass:"mr-2"},[_vm._v("Pattern Helper")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticClass:"help-tooltip"},[_vm._v(" The input in the SKU filter will be applied as pattern. ")])])]},proxy:true},{key:"form",fn:function(){return [_c('div',{staticClass:"pattern-helper-container"},[_c('ProductOverview',{staticClass:"pattern-helper-product-overview",attrs:{"open-details-in-tab":"","hide-price-data":"","query":_vm.query,"search-props":{
          possibleFilters: _vm.possibleFilters,
          disableFilterCreation: true,
          disableFilterDeletion: true,
          disableFullTextSearch: true,
        }},on:{"query-changed":_vm.parseQuery}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }