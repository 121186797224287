<template>
  <DialogForm
    data-test-id="patternHelperDialog"
    save-btn-label="Apply"
    close-btn-label="Cancel"
    save-btn-test-id="applyPatternBtn"
    close-btn-test-id="cancelPatternBtn"
    @close="$emit('close', false)"
    @save="applyPattern"
  >
    <template #title>
      <v-toolbar-title class="mr-2">Pattern Helper</v-toolbar-title>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
        </template>
        <div class="help-tooltip">
          The input in the SKU filter will be applied as pattern.
        </div>
      </v-tooltip>
    </template>
    <template #form>
      <div class="pattern-helper-container">
        <ProductOverview
          open-details-in-tab
          hide-price-data
          class="pattern-helper-product-overview"
          :query="query"
          :search-props="{
            possibleFilters,
            disableFilterCreation: true,
            disableFilterDeletion: true,
            disableFullTextSearch: true,
          }"
          @query-changed="parseQuery"
        />
      </div>
    </template>
  </DialogForm>
</template>

<script>
import DialogForm from "../../../common/templates/DialogForm";
import ProductOverview from "../../../products/ProductOverview";
import esMixin from "../../../../mixins/elastic-search-mixin";
export default {
  mixins: [esMixin],
  components: {
    DialogForm,
    ProductOverview,
  },

  provide() {
    return {
      //provide page height, so that the product overview
      //calculates the correct table height
      getPageHeight: () => this.productOverviewHeight,
    };
  },

  inject: {
    getServiceProvider: {
      default() {
        return () => {
          return null;
        };
      },
    },

    getPageHeight: {},
  },

  props: {
    value: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      pattern: this.value || "*",
    };
  },

  methods: {
    parseQuery(query) {
      const filterArray = query?.bool?.filter ?? [];
      let pattern = this.pattern;
      filterArray.forEach((filter) => {
        const tmp = this.$getObjectValueByPath(
          filter,
          "wildcard.sku+keyword.value"
        );
        if (tmp) pattern = tmp;
      });
      this.pattern = pattern;
    },

    async applyPattern() {
      this.$emit("input", this.pattern);
      await this.$nextTick();
      this.$emit("close");
    },
  },

  computed: {
    productOverviewHeight() {
      const pageHeight = this.getPageHeight();
      return (pageHeight - 76) / 1.3;
    },

    query() {
      let filter = [];
      filter.push({
        wildcard: {
          ["sku.keyword"]: {
            value: this.pattern,
          },
        },
      });

      if (this.hasServiceProvider) {
        //if this is in service provider context, only show items which
        //are from the given service provider
        const serviceProvider = this.getServiceProvider();
        filter.push({
          term: {
            ["serviceProviderId.keyword"]: {
              value: serviceProvider?.id,
            },
          },
        });
      }
      return {
        bool: {
          filter,
        },
      };
    },

    hasServiceProvider() {
      const serviceProvider = this.getServiceProvider();
      return serviceProvider !== undefined && serviceProvider !== null;
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    possibleFilters() {
      let filters = [{ text: "SKU", property: "sku", type: "text" }];

      if (this.hasServiceProvider) {
        const serviceProvider = this.getServiceProvider();
        filters.push({
          text: "Service provider ID",
          property: "serviceProviderId",
          type: "list",
          options: [
            {
              value: serviceProvider.id,
              text: serviceProvider.id,
            },
          ],
        });
      }

      return filters;
    },
  },
};
</script>

<style scoped>
.pattern-helper-container {
  display: flex;
  flex: 1 1 100%;
}
</style>