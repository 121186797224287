<template>
  <DetailView
    class="service-provider-detail"
    data-test-id="serviceProviderDetail"
    :title="title"
    @close="$emit('close', false)"
  >
    <template #content>
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col>
            <v-card outlined flat class="service-provider-information">
              <v-card-title>
                <v-icon left>mdi-hand-coin</v-icon>
                Overview
              </v-card-title>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-identifier</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title data-test-id="serviceProviderId">
                      {{ serviceProvider.id }}
                    </v-list-item-title>
                    <v-list-item-subtitle>ID</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title data-test-id="serviceProviderName">
                      {{ serviceProvider.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Name</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="serviceProvider.iban">
                  <v-list-item-icon>
                    <v-icon>mdi-bank</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title data-test-id="serviceProviderIban">
                      {{ serviceProvider.iban }}
                    </v-list-item-title>
                    <v-list-item-subtitle>IBAN</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col>
            <AddressCard
              v-if="serviceProvider.address"
              title="Address"
              class="service-provider-address"
              data-test-id="serviceProviderAddress"
              :address="serviceProvider.address"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <ContractList
              is-service-provider
              :entity="serviceProvider"
              :domain="selectedDomain"
              data-test-id="serviceProviderContracts"
              class="service-provider-contracts"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </DetailView>
</template>

<script>
import DetailView from "../common/templates/DetailView";
import AddressCard from "../orders/AddressCard";
import ContractList from "../commission-contract/ContractsList";
export default {
  components: {
    DetailView,
    AddressCard,
    ContractList,
  },

  props: {
    provider: {
      type: Object,
      required: true,
    },
  },

  provide() {
    return {
      getServiceProvider: () => {
        return this.serviceProvider;
      },
    };
  },

  data() {
    return {
      serviceProvider: this.$cloneObject(this.provider) ?? {},
    };
  },

  computed: {
    title() {
      return this.provider?.name;
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },
  },
};
</script>

<style scoped>
.service-provider-detail::v-deep .detail-view-body {
  padding: 0 8px;
}

.service-provider-detail .service-provider-information {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.service-provider-detail .service-provider-address {
  flex: 0 1 auto;
}

.service-provider-detail .service-provider-contracts {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin: 8px 0px;
  padding: 0;
}
</style>