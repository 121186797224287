var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"reseller-contract-container",attrs:{"fluid":"","fill-height":"","data-test-id":"ContractsList"}},[_c('v-card',{staticClass:"reseller-contracts contract-section",attrs:{"flat":"","id":"contracts"}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-sign")]),_c('span',[_vm._v("Contracts")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","data-test-id":"addContractBtn","disabled":_vm.disabled || _vm.runningAction},on:{"click":_vm.addContract}},[_vm._v(" New Contract ")])],1),_c('v-data-table',{staticClass:"reseller-contracts-table",attrs:{"dense":"","sort-by":"preferred","sort-desc":"","show-expand":"","hide-default-footer":"","no-data-text":"No contracts found","data-test-id":"contractList","item-key":"id","items":_vm.contracts,"headers":_vm.contractHeaders,"loading":_vm.runningAction,"expanded":_vm.expanded,"items-per-page":-1},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(isExpanded)?_c('v-btn',{attrs:{"icon":"","data-test-id":'contract_' + item.id + '_hide_btn'},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_c('v-btn',{attrs:{"icon":"","data-test-id":'contract_' + item.id + '_expand_btn'},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}},{key:"item.srcDomain",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":_vm.$router.resolve({
              name: 'catalogs',
              params: {
                domain: item.srcDomain,
              },
            }).href,"data-test-id":'reseller-contract-srcdomain' + item.id}},[_vm._v(" "+_vm._s(item.srcDomain)+" ")])]}},{key:"item.validFrom",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getLocalizedDate(item.validFrom, { year: "numeric", month: "numeric", day: "numeric", }))+" ")]}},{key:"item.validUntil",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getLocalizedDate(item.validUntil, { year: "numeric", month: "numeric", day: "numeric", }))+" ")]}},{key:"item.operations",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.operations.join())+" ")]}},{key:"item.paymentTypes",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.paymentTypes.join())+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","disabled":_vm.disabled || _vm.runningAction,"data-test-id":'contract_' + item.id + '_deleteBtn'},on:{"click":function($event){return _vm.deleteContract(item)}}},[(!_vm.disabled)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
            var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.contractHeaders.length + 1}},[_c('v-container',{attrs:{"fluid":"","data-test-id":'contract_' + item.id + '_detail'}},[_c('Contract',{attrs:{"entity":_vm.entity,"input-contract":item,"disabled":_vm.disabled || _vm.runningAction,"is-service-provider":_vm.isServiceProvider,"domain":_vm.domain},on:{"refresh":function($event){return _vm.loadContracts()}}}),_c('ContractItemsList',{attrs:{"entity":_vm.entity,"contract":item,"disabled":_vm.disabled || _vm.runningAction,"domain":_vm.getItemDomain(item),"data-test-id":"resellerContractItemsList"}})],1)],1)]}}])})],1),(_vm.contractDialog)?_c('v-dialog',{attrs:{"persistent":"","width":"500px"},model:{value:(_vm.contractDialog),callback:function ($$v) {_vm.contractDialog=$$v},expression:"contractDialog"}},[_c('Contract',{attrs:{"data-test-id":"contractDialog","input-contract":{},"is-service-provider":_vm.isServiceProvider,"domain":_vm.domain,"entity":_vm.entity},on:{"close":_vm.closeContractDialog}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }