<template>
  <v-container
    fluid
    fill-height
    class="reseller-contract-items-container"
    data-test-id="ContractItemsList"
  >
    <v-card flat class="reseller-contract-items" id="contractItems">
      <v-card-title>
        <v-icon left>mdi-sitemap-outline</v-icon>
        Contract Items
        <v-spacer></v-spacer>
        <v-btn
          v-if="isEditActive"
          outlined
          color="green"
          data-test-id="saveAllContractItemBtn"
          class="mr-2"
          :disabled="disabled || runningAction"
          @click="upsertAllContractItems"
        >
          Save all
        </v-btn>
        <v-btn
          color="primary"
          @click="addContractItem"
          :disabled="disabled || runningAction"
          data-test-id="addContractItemBtn"
        >
          New contract item
        </v-btn>
      </v-card-title>
      <v-form ref="contractItemForm">
        <v-data-table
          dense
          sort-by="preferred"
          sort-desc
          hide-default-footer
          class="reseller-contract-items-table"
          :loading="runningAction"
          :items="displayedItems"
          :headers="contractItemHeaders"
          :items-per-page="-1"
        >
          <!-- eslint-disable-next-line -->
          <template #item.skuPattern="{ item }">
            <div v-if="item.edit" class="contract-item-field">
              <PatternHelperInput
                v-model="item.skuPattern"
                outlined
                dense
                hide-details="auto"
                class="my-1"
                placeholder="*.SKIPASS.*"
                background-color="white"
                :ref="'skuPatternInput_' + item.id"
                :disabled="disabled || runningAction"
                :rules="[ruleSet.required]"
                :is-service-provider="isServiceProvider"
                :parent-id="item.id"
              />
            </div>
            <div v-else>{{ item.skuPattern }}</div>
          </template>

          <!-- eslint-disable-next-line -->
          <template #item.commissionPercentage="{ item }">
            <div v-if="item.edit" class="contract-item-field">
              <v-text-field
                v-model="item.commissionPercentage"
                outlined
                dense
                hide-details="auto"
                class="my-1"
                placeholder="5"
                type="number"
                background-color="white"
                :disabled="disabled || runningAction"
                :rules="[ruleSet.required]"
                :data-test-id="'commissionPercentageInput_' + item.id"
              />
            </div>
            <div v-else>{{ item.commissionPercentage }}</div>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <div
              :class="{
                'action-btn-container': true,
                edit: item.edit,
              }"
            >
              <v-btn
                v-if="item.edit || item.isNew"
                small
                icon
                color="green"
                :disabled="
                  disabled ||
                  runningAction ||
                  !item.skuPattern ||
                  !item.commissionPercentage
                "
                :data-test-id="'contractItemSaveBtn_' + item.id"
                @click="upsertContractItem(item)"
              >
                <v-icon small>mdi-content-save</v-icon>
              </v-btn>
              <v-btn
                v-else
                small
                icon
                :disabled="disabled || runningAction"
                :data-test-id="'contractItemEditBtn_' + item.id"
                @click="toggleEditMode(item, true)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="item.edit && !item.isNew"
                small
                icon
                :disabled="disabled || runningAction"
                :data-test-id="'contractItemCancelBtn_' + item.id"
                @click="toggleEditMode(item, false)"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
              <v-btn
                v-else
                small
                icon
                :disabled="disabled || runningAction"
                :data-test-id="'contractItemDeleteBtn_' + item.id"
                @click="deleteContractItem(item)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import PatternHelperInput from "./pattern/PatternHelperInput";
export default {
  components: {
    PatternHelperInput,
  },

  props: {
    contract: {
      required: true,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    domain: {
      type: String,
      required: true,
    },
    isServiceProvider: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      contractItems: [],
      runningAction: false,
      newContractItems: [],
      savedItems: {},
    };
  },

  async mounted() {
    await this.loadContractItems();
  },

  methods: {
    async loadContractItems() {
      try {
        this.runningAction = true;
        const prevContractItems = this.$cloneObject(this.contractItems);
        //load the resellers contractItems and categorize it into billing and shipping contractItem
        const res =
          await this.$store.$coreApi.coreCommissionApi.getContractItems(
            this.domain,
            this.contract.id
          );
        const contractItems = res?.result ?? [];

        this.contractItems = contractItems.map((item) => {
          const prevItem = prevContractItems.find(({ id }) => id === item.id);
          if (prevItem?.edit) {
            //restore the previously edited items
            return {
              ...item,
              edit: prevItem.edit,
              skuPattern: prevItem.skuPattern,
              commissionPercentage: prevItem.commissionPercentage,
            };
          }
          return item;
        });
      } finally {
        if (!this.contractItems) this.contractItems = [];
        this.runningAction = false;
      }
    },

    async upsertContractItem(contractItem, preventReload = false) {
      try {
        this.runningAction = true;
        const res =
          await this.$store.$coreApi.coreCommissionApi.upsertContractItem(
            this.domain,
            this.contract.id,
            contractItem,
            {
              successMsg:
                "Contract item " + (contractItem.isNew ? "created" : "saved"),
            }
          );

        if (!res?.ok) return;
        if (contractItem.isNew) {
          //Remove from new contract items
          const idx = this.newContractItems.findIndex(
            ({ id }) => id === contractItem.id
          );
          this.$delete(this.newContractItems, idx);
          this.$delete(contractItem, "isNew");
        }
        this.$delete(this.savedItems, contractItem.id);
        this.$delete(contractItem, "edit");
        if (!preventReload) await this.loadContractItems();
      } finally {
        this.runningAction = false;
      }
    },

    async deleteContractItem(contractItem) {
      if (contractItem.isNew) {
        const idx = this.newContractItems.findIndex(
          ({ id }) => id === contractItem.id
        );
        this.$delete(this.newContractItems, idx);
        return;
      }

      if (!contractItem.deleted) {
        if (
          await this.$confirm(
            "Delete contract item?",
            "Are you sure you want to delete the contract item ?"
          )
        ) {
          this.runningAction = true;
          try {
            const res =
              await this.$store.$coreApi.coreCommissionApi.deleteContractItem(
                this.domain,
                this.contract.id,
                contractItem.id,
                {
                  successMsg: "Contract item deleted",
                }
              );

            if (!res?.ok) return;
            this.$delete(this.savedItems, contractItem.id);
            await this.loadContractItems();
          } finally {
            this.runningAction = false;
          }
        }
      }
    },

    addContractItem() {
      const id = this.$uuid.v4();
      this.newContractItems.push({
        id,
        skuPattern: null,
        commissionPercentage: null,
        edit: true,
        isNew: true,
      });

      this.$nextTick(() => {
        const input = this.$refs?.["skuPatternInput_" + id]?.$children?.[0];
        input?.focus();
      });
    },

    async upsertAllContractItems() {
      const form = this.$refs.contractItemForm;
      if (!this.$validateVForm(form)) return;
      try {
        this.runningAction = true;
        const editedItems = this.displayedItems.filter((item) => item.edit);
        for (const item of editedItems) {
          await this.upsertContractItem(item, true);
          this.runningAction = true;
        }

        await this.loadContractItems();
      } finally {
        this.runningAction = false;
      }
    },

    toggleEditMode(item, enableEdit) {
      const id = item.id;
      if (enableEdit) {
        const oldItem = this.$cloneObject(item);
        this.$set(this.savedItems, id, oldItem);
      } else {
        const savedItem = this.savedItems[id];
        if (savedItem) {
          this.$set(item, "skuPattern", savedItem.skuPattern);
          this.$set(
            item,
            "commissionPercentage",
            savedItem.commissionPercentage
          );
        }
        this.$delete(this.savedItems, id);
      }
      this.$set(item, "edit", enableEdit);
    },
  },

  computed: {
    contractItemHeaders() {
      return [
        { text: "SKU Pattern", value: "skuPattern" },
        {
          text: "Commission percentage",
          value: "commissionPercentage",
          width: "256px",
        },
        { text: "Actions", value: "actions", sortable: false, width: "64px" },
      ];
    },

    displayedItems() {
      return this.contractItems.concat(this.newContractItems);
    },

    ruleSet() {
      return {
        required: (val) => !!val || val === 0 || "Value is required",
      };
    },

    isEditActive() {
      return this.displayedItems.some((item) => item.edit);
    },
  },
};
</script>

<style scoped>
.reseller-contract-items-container {
  padding: 0 12px;
  display: flex;
  flex: 1 1 100%;
}

.reseller-contract-items-container > .reseller-contract-items {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.reseller-contract-items-container
  > .reseller-contract-items
  .action-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.reseller-contract-items-container
  > .reseller-contract-items
  .contract-item-field {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  height: 100%;
}
</style>