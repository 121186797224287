<template>
  <div>
    <v-combobox
      outlined
      dense
      hide-details="auto"
      placeholder="*.SKIPASS.*"
      background-color="white"
      ref="patternInput"
      no-filter
      :value="dummyModel"
      :disabled="disabled"
      :rules="rules"
      :loading="runningAction"
      :items="items"
      :data-test-id="'skuPatternInput_' + parentId"
      @focus="$emit('focus')"
      @change="onAutoCompleteSelection"
      @input.native="updateModel"
    >
      <template #no-data>
        <div class="d-flex justify-center">
          <v-subheader>{{ noDataText }}</v-subheader>
        </div>
      </template>

      <template #prepend-item>
        <div class="d-flex flex-row flex-grow-1 align-center px-2 py-1">
          <div class="text-caption">
            <strong>Matching products for pattern (first 50 shown)</strong>
          </div>
          <v-spacer />
          <div class="product-ui-link">
            <v-btn
              text
              color="primary"
              x-small
              target="_blank"
              data-test-id="showInProductUiBtn"
              @click="showHelperDialog = true"
            >
              <v-icon left x-small>mdi-open-in-new</v-icon>
              Open pattern helper
            </v-btn>
          </div>
        </div>
        <v-divider class="my-2" />
      </template>

      <template #item="{ item }">
        <v-list-item>
          <v-list-item-title :data-test-id="'product_' + item.text">{{
            item.text
          }}</v-list-item-title>
        </v-list-item>
      </template>

      <v-dialog> </v-dialog>
    </v-combobox>

    <v-dialog v-model="showHelperDialog">
      <PatternHelperDialog
        v-if="showHelperDialog"
        v-model="pattern"
        @close="showHelperDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import esMixin from "../../../../mixins/elastic-search-mixin";
import PatternHelperDialog from "./PatternHelperDialog.vue";
export default {
  mixins: [esMixin],

  components: {
    PatternHelperDialog,
  },

  inject: {
    getServiceProvider: {
      default() {
        return () => {
          return null;
        };
      },
    },
  },

  props: {
    value: {
      type: String,
      required: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    rules: {
      type: Array,
      required: false,
      validator: (rules) => {
        return rules.every((rule) => typeof rule === "function");
      },
    },

    parentId: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      pattern: this.value,
      dummyModel: this.value,
      runningAction: false,
      entries: [],
      showHelperDialog: false,
      timeout: 0,
    };
  },

  watch: {
    value(value) {
      if (this.pattern === value) return;
      this.pattern = value;
    },

    pattern(pattern) {
      this.$emit("input", pattern);
      this.dummyModel = pattern;
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.loadProducts();
      }, 300);
    },
  },

  mounted() {
    this.$nextTick(() => this.loadProducts());
  },

  methods: {
    onAutoCompleteSelection() {
      if (this.dummyModel === this.pattern) return;
      this.pattern = this.dummyModel;
    },

    updateModel(event) {
      this.pattern = event?.target?.value;
    },

    async loadProducts() {
      try {
        this.runningAction = true;
        let query = this.searchRequestBody({
          limit: 50,
          sort: [{ "sku.keyword": "asc" }],
          _source: ["sku"],
        });
        query.query.bool.must = this.getFilters();

        const result = await this.$store.$coreApi.coreElasticSearchApi.search({
          domain: this.selectedDomain,
          indexType: "product",
          body: query,
        });

        this.entries = result?.items ?? [];

        this.$nextTick(() => {
          if (this.$refs.patternInput) {
            this.$refs.patternInput.activateMenu();
            this.$refs.patternInput.updateMenuDimensions();
          }
        });
      } finally {
        this.runningAction = false;
      }
    },

    getFilters() {
      let filter = [];
      if (this.pattern) {
        filter.push({
          wildcard: {
            ["sku.keyword"]: {
              value: this.pattern,
            },
          },
        });
      }

      if (this.hasServiceProvider) {
        //if this is in service provider context, only show items which
        //are from the given service provider
        const serviceProvider = this.getServiceProvider();
        filter.push({
          term: {
            ["serviceProviderId.keyword"]: {
              value: serviceProvider.id,
            },
          },
        });
      }
      return {
        bool: {
          filter,
        },
      };
    },

    async openProducts() {
      await this.$router.push(this.productUiRoute);
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    items() {
      return this.entries.map((entry) => {
        return {
          text: entry._source.sku,
        };
      });
    },

    productUiHref() {
      const filterQuery = this.getFilters();
      const filter = this.$urlEncode(JSON.stringify(filterQuery));
      return this.$router.resolve({
        name: "products",
        params: {
          domain: this.selectedDomain,
        },
        query: {
          filter,
        },
      }).href;
    },

    hasServiceProvider() {
      return (
        this.getServiceProvider() !== undefined &&
        this.getServiceProvider() !== null
      );
    },

    noDataText() {
      if (this.hasServiceProvider) {
        return "No products found for this service provider and pattern";
      }
      return "No products found for this pattern";
    },
  },
};
</script>

<style scoped>
.product-ui-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.75rem;
}

.product-ui-link > .v-icon {
  color: var(--v-anchor-base);
}
</style>