<template>
  <v-container
    fluid
    fill-height
    class="reseller-contract-container"
    data-test-id="ContractsList"
  >
    <v-card flat class="reseller-contracts contract-section" id="contracts">
      <v-card-title>
        <v-icon left>mdi-file-sign</v-icon>
        <span>Contracts</span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          data-test-id="addContractBtn"
          :disabled="disabled || runningAction"
          @click="addContract"
        >
          New Contract
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        sort-by="preferred"
        sort-desc
        show-expand
        hide-default-footer
        no-data-text="No contracts found"
        class="reseller-contracts-table"
        data-test-id="contractList"
        item-key="id"
        :items="contracts"
        :headers="contractHeaders"
        :loading="runningAction"
        :expanded.sync="expanded"
        :items-per-page="-1"
      >
        <!-- eslint-disable-next-line -->
        <template #item.data-table-expand="{ item, isExpanded, expand }">
          <v-btn
            icon
            v-if="isExpanded"
            @click="expand(false)"
            :data-test-id="'contract_' + item.id + '_hide_btn'"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
          <v-btn
            icon
            v-else
            @click="expand(true)"
            :data-test-id="'contract_' + item.id + '_expand_btn'"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.srcDomain="{ item }">
          <a
            target="_blank"
            :href="
              $router.resolve({
                name: 'catalogs',
                params: {
                  domain: item.srcDomain,
                },
              }).href
            "
            :data-test-id="'reseller-contract-srcdomain' + item.id"
          >
            {{ item.srcDomain }}
          </a>
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.validFrom="{ item }">
          {{
            $getLocalizedDate(item.validFrom, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })
          }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.validUntil="{ item }">
          {{
            $getLocalizedDate(item.validUntil, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })
          }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.operations="{ item }">
          {{ item.operations.join() }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.paymentTypes="{ item }">
          {{ item.paymentTypes.join() }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <v-btn
            small
            icon
            @click="deleteContract(item)"
            :disabled="disabled || runningAction"
            :data-test-id="'contract_' + item.id + '_deleteBtn'"
          >
            <v-icon v-if="!disabled" small>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template #expanded-item="{ item }">
          <td :colspan="contractHeaders.length + 1">
            <v-container
              fluid
              :data-test-id="'contract_' + item.id + '_detail'"
            >
              <Contract
                :entity="entity"
                :input-contract="item"
                :disabled="disabled || runningAction"
                :is-service-provider="isServiceProvider"
                :domain="domain"
                @refresh="loadContracts()"
              />
              <!-- CONTRACT ITEM -->
              <ContractItemsList
                :entity="entity"
                :contract="item"
                :disabled="disabled || runningAction"
                :domain="getItemDomain(item)"
                data-test-id="resellerContractItemsList"
              />
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <!-- CREATE Contract DIALOG -->
    <v-dialog
      persistent
      v-model="contractDialog"
      width="500px"
      v-if="contractDialog"
    >
      <Contract
        data-test-id="contractDialog"
        :input-contract="{}"
        :is-service-provider="isServiceProvider"
        :domain="domain"
        :entity="entity"
        @close="closeContractDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Contract from "./Contract";
import ContractItemsList from "./contractitem/ContractItemsList";

export default {
  props: {
    domain: {
      type: String,
      required: true,
    },

    entity: {
      required: true,
      type: Object,
    },

    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },

    isServiceProvider: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    Contract,
    ContractItemsList,
  },

  data() {
    return {
      contractDialog: false,
      contracts: [],
      expanded: [],
      contractItems: [],
      runningAction: false,
    };
  },

  async mounted() {
    await this.loadContracts();
  },

  watch: {
    "entity.id": function () {
      //another reseller has been selected
      this.loadContracts();
    },
  },

  methods: {
    async loadContracts() {
      try {
        this.runningAction = true;
        if (this.isServiceProvider) {
          const contracts =
            await this.$store.$coreApi.coreCommissionApi.getContracts(
              this.domain,
              this.entity.id
            );
          this.contracts = contracts?.result ?? [];
        } else {
          if (this.entity.id) {
            //load the resellers contracts and categorize it into billing and shipping contract
            const contracts =
              await this.$store.$b2bApi.b2bResellerApi.getContracts(
                this.domain,
                this.entity.id
              );
            this.contracts = Array.isArray(contracts) ? contracts : [];
          }
        }
      } finally {
        this.runningAction = false;
      }
    },

    addContract() {
      this.contractDialog = true;
    },

    async deleteContract(contract) {
      if (
        await this.$confirm(
          "Delete contract?",
          "Are you sure you want to delete contract " +
            contract.id +
            " (" +
            contract.validFrom +
            " " +
            contract.validUntil +
            ")" +
            "?"
        )
      ) {
        this.runningAction = true;
        try {
          if (this.isServiceProvider) {
            const res =
              await this.$store.$coreApi.coreCommissionApi.deleteContract(
                this.domain,
                contract.id
              );

            if (!res?.ok) return;
          } else {
            const res = await this.$store.$b2bApi.b2bResellerApi.deleteContract(
              this.domain,
              this.entity.id,
              contract
            );

            if (!res?.ok) return;
          }
          await this.loadContracts();
        } finally {
          this.runningAction = false;
        }
      }
    },

    async closeContractDialog(newContractId) {
      this.contractDialog = false;
      if (newContractId) {
        await this.loadContracts();
        await this.$nextTick();
        this.expanded = this.contracts.filter(
          (contract) => contract?.id === newContractId
        );
      }
    },

    getItemDomain(contract) {
      return contract?.srcDomain ?? this.domain;
    },
  },

  computed: {
    contractHeaders() {
      if (this.isServiceProvider) {
        return [
          { text: "Valid From", value: "validFrom" },
          { text: "Valid To", value: "validUntil" },
          { text: "Contact person", value: "contactPerson" },
          { text: "Actions", value: "actions", sortable: false },
        ];
      }
      return [
        { text: "Source domain", value: "srcDomain" },
        { text: "Valid From", value: "validFrom" },
        { text: "Valid To", value: "validUntil" },
        { text: "Contact person", value: "contactPerson" },
        { text: "Payment types", value: "paymentTypes" },
        { text: "Operations", value: "operations" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
};
</script>

<style scoped>
.action-btn-container {
  padding: 0;
  display: flex;
}

.contract-section {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
}
</style>